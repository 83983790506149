<template>
    <div class="yj-conten"
         style="
      min-height: calc(100% - 48px);
      background-color: #fff;
      width: calc(100% - 32px);
      min-width: fit-content;
    ">
        <a-form-model ref="ruleForm"
                      :model="FormModel"
                      :rules="rules"
                      layout="horizontal"
                      :label-col="labelCol"
                      :wrapper-col="wrapperCol">
            <div v-if="baseinformation == true">
                <div class="steps-content">
                    <a-card title="基本信息" :bordered="false">
                        <a-form-model-item has-feedback prop="Name" label="商品名称:">
                            <a-input placeholder="请输入商品名称"
                                     v-model="FormModel.Name"
                                     type="text"
                                     :disabled="disabled" />
                        </a-form-model-item>
                        <a-form-model-item has-feedback
                                           prop="ProductExImage"
                                           label="商品图片 :">
                            <a-upload name="image"
                                      :data="ParamData"
                                      list-type="picture-card"
                                      class="avatar-uploader"
                                      action="/FileServer/Upload?secpath=product"
                                      :remove="removeImg"
                                      :fileList="FormModel.ProductExImage"
                                      accept=".jpg,.png,.gif,.bmp"
                                      @change="imgChange"
                                      :disabled="disabled">
                                <a-icon type="plus" />
                                <div class="ant-upload-text">上传图片</div>
                            </a-upload>
                        </a-form-model-item>
                        <div v-if="ProductType == 1">
                            <a-form-model-item has-feedback
                                               prop="cars"
                                               label="车型:"
                                               v-if="disabled == false">
                                <template v-for="car in cars">
                                    <a-checkable-tag :key="car.Value"
                                                     v-model="car.Checked"
                                                     style="
                      font-size: 15px;
                      line-height: 40px;
                      text-align: center;
                      margin-bottom: 10px;
                      border: 1px solid LightGrey;
                      padding-left: 15px;
                      padding-right: 15px;
                    ">
                                        {{ car.Text }}
                                    </a-checkable-tag>
                                </template>
                            </a-form-model-item>
                            <a-form-model-item has-feedback
                                               prop="carsdisabled"
                                               label="车型:"
                                               v-if="disabled == true">
                                <template v-for="car in carsdisabled">
                                    <a-checkable-tag :key="car"
                                                     :checked="true"
                                                     style="
                      font-size: 15px;
                      line-height: 40px;
                      text-align: center;
                      margin-bottom: 10px;
                      border: 1px solid LightGrey;
                      padding-left: 15px;
                      padding-right: 15px;
                    ">
                                        {{ car }}
                                    </a-checkable-tag>
                                </template>
                            </a-form-model-item>
                            <a-form-model-item has-feedback prop="Intended" label="适用人群:">
                                <a-input placeholder="适用对象"
                                         v-model="Intended"
                                         type="text"
                                         :disabled="disabled" />
                            </a-form-model-item>
                            <a-form-model-item has-feedback
                                               prop="teams"
                                               label="班型亮点:"
                                               v-if="disabled == false">
                                <template v-for="team in teams">
                                    <a-checkable-tag :key="team.Value"
                                                     v-model="team.Checked"
                                                     style="
                      font-size: 15px;
                      line-height: 40px;
                      text-align: center;
                      margin-bottom: 10px;
                      border: 1px solid LightGrey;
                      padding-left: 15px;
                      padding-right: 15px;
                    ">
                                        {{ team.Text }}
                                    </a-checkable-tag>
                                </template>
                            </a-form-model-item>
                            <a-form-model-item has-feedback
                                               prop="teamsdisabled"
                                               label="班型亮点:"
                                               v-if="disabled == true">
                                <template v-for="team in teamsdisabled">
                                    <a-checkable-tag :key="team"
                                                     :checked="true"
                                                     style="
                      font-size: 15px;
                      line-height: 40px;
                      text-align: center;
                      margin-bottom: 10px;
                      border: 1px solid LightGrey;
                      padding-left: 15px;
                      padding-right: 15px;
                    ">
                                        {{ team }}
                                    </a-checkable-tag>
                                </template>
                            </a-form-model-item>
                        </div>
                        <div v-if="ProductType == 2">
                            <a-form-model-item has-feedback
                                               prop="cars"
                                               label="科目:"
                                               v-if="disabled == false">
                                <template v-for="c in course">
                                    <a-checkable-tag :key="c.Value"
                                                     v-model="c.Checked"
                                                     style="
                      width: 50px;
                      font-size: 15px;
                      line-height: 40px;
                      text-align: center;
                      margin-bottom: 10px;
                      border: 1px solid LightGrey;
                    ">
                                        {{ c.Text }}
                                    </a-checkable-tag>
                                </template>
                            </a-form-model-item>
                            <a-form-model-item has-feedback
                                               prop="coursedisabled"
                                               label="科目:"
                                               v-if="disabled == true">
                                <template v-for="course in coursedisabled">
                                    <a-checkable-tag :key="course"
                                                     :checked="true"
                                                     style="
                      width: 50px;
                      font-size: 15px;
                      line-height: 40px;
                      text-align: center;
                      margin-bottom: 10px;
                      border: 1px solid LightGrey;
                    ">
                                        {{ course }}
                                    </a-checkable-tag>
                                </template>
                            </a-form-model-item>
                            <a-form-model-item has-feedback
                                               prop="Intendedcourse"
                                               label="适用人群:">
                                <a-input placeholder="适用对象"
                                         v-model="Intendedcourse"
                                         type="text"
                                         :disabled="disabled" />
                            </a-form-model-item>
                        </div>
                        <a-form-model-item has-feedback
                                           prop="Summary"
                                           label="商品简介:"
                                           v-if="ProductType == 2 || ProductType == 1">
                            <a-input placeholder="商品简介~"
                                     v-model="FormModel.Summary"
                                     type="textarea"
                                     :disabled="disabled" />
                        </a-form-model-item>
                        <a-form-model-item has-feedback
                                           prop="SaleMoney"
                                           label="商品价格(元):">
                            <a-input-number :min="0"
                                            :step="0.01"
                                            v-model="FormModel.SaleMoney"
                                            :disabled="disabled"
                                            :precision="2"
                                            @change="SaleMoneyChange" />
                        </a-form-model-item>
                        <a-form-model-item has-feedback
                                           label="是否预交费:"
                                           v-if="ProductType == 2 || ProductType == 1">
                            <a-switch v-model="Advanceshows"
                                      @change="AdvanceChange"
                                      :disabled="Advancedisabled">
                                <a-icon slot="checkedChildren" type="check" />
                                <a-icon slot="unCheckedChildren" type="close" />
                            </a-switch>
                        </a-form-model-item>
                        <a-form-model-item has-feedback
                                           prop="AdvanceMoney"
                                           label="预付款(元) :"
                                           v-if="
                Advanceshows == true && (ProductType == 2 || ProductType == 1)
              ">
                            <a-input-number :min="0.01"
                                            :step="0.01"
                                            v-model="FormModel.AdvanceMoney"
                                            :disabled="disabled"
                                            :precision="2" />
                        </a-form-model-item>
                        <a-form-model-item has-feedback prop="BuyCountShow" label="销量:">
                            <a-input-number :min="0"
                                            v-model="FormModel.BuyCountShow"
                                            :disabled="disabled"
                                            :precision="0" />
                        </a-form-model-item>
                        <a-form-model-item has-feedback prop="BuyCountShow" label="库存:">
                            <a-input-number :min="0"
                                            v-model="Inventory"
                                            :disabled="disabled"
                                            :precision="0" />
                        </a-form-model-item>
                        <div v-if="OriginalMoneyShow == true || ProductType == 5">
                            <a-form-model-item has-feedback
                                               prop="OriginalMoney"
                                               label="原价:">
                                <a-input-number :min="0"
                                                :step="0"
                                                v-model="FormModel.OriginalMoney"
                                                :disabled="disabled"
                                                :precision="2" />
                            </a-form-model-item>
                        </div>
                        <a-form-model-item has-feedback
                                           prop="ContentText"
                                           label="商品详情 :">
                            <Rich v-bind:value.sync="FormModel.ContentText"></Rich>
                        </a-form-model-item>
                    </a-card>
                    <br />
                    <a-card title="商品规格" :bordered="false" v-if="CustomSpecificationData>0">
                        <!--<a-layout>
                          <a-layout-header style="background: #ffffff">
                            规格分类：
                            <a-select
                              :default-value="GgOneID.toString()"
                              :value="GgOneID.toString()"
                              style="width: 250px"
                              @change="GgChangeOne"
                            >
                              <a-select-option
                                v-for="i in SelectData"
                                :key="i.ID.toString()"
                              >
                                {{ i.SFName }}
                              </a-select-option>
                            </a-select>

                            <a-button
                              type="primary"
                              @click="AddSpecification(GgOneID)"
                              style="margin-left: 20px"
                              >新增产品规格</a-button
                            >
                          </a-layout-header>
                          <a-layout-content style="background: #ffffff; padding-left: 50px">
                            <span v-for="item in OneGoodsSpecifiaction" :key="item.ID">
                              具体规格：
                              <a-input
                                placeholder="产品规格名称"
                                v-model="item.Name"
                                style="width: 200px; margin: 0 8px 8px 0"
                              />
                              <a-button type="link" @click="DeleteItem(item.ID,GgOneID)"
                                >删除</a-button
                              ><br />
                            </span>
                          </a-layout-content>
                        </a-layout>-->
                        <a-layout style="background:#fff;" v-for="(item,index) in GoodsSpecificationList" :key="index">
                           
                            <a-layout-header style="background: #ffffff">
                                <span style="width: 50px;display: inline-block;">规格：</span>
                                <a-select :value="item.defaultValue.toString()"
                                          style="width: 250px"
                                          @change="value => NewGgChangeOne(value, item)">
                                    <a-select-option v-for="i in item.SelectData"
                                                     :key="i.ID.toString()">
                                        {{ i.SFName }}
                                    </a-select-option>
                                </a-select>

                                <a-button type="link"
                                          @click="NewDelSpecification(index)"
                                          style="margin-left: 20px">删除规格</a-button>
                              
                                <a-button type="primary" @click="ShowAddSpecification()">添加</a-button>
                                <div>
                                    <a-modal v-model="visible"
                                             title="新增规格名称"
                                             :confirm-loading="confirmLoading"
                                             @ok="handleOk(item.defaultValue,item.SelectedList)"
                                             @cancel="handleCancel">
                                        <a-input placeholder="请输入规格名称,以;隔开" v-model="SFName"/>
                                    </a-modal>
                                </div>

                            </a-layout-header>

                            <a-layout-content style="background: #ffffff; padding-left: 50px;padding-bottom: 10px;">
                                <span v-for="(it,index) in item.SelectedList" :key="it.ID" style="margin-left:50px">
                                 
                                    <a-input placeholder="请输入规格名称"
                                             v-model="it.Name"
                                             style="width: 200px; margin: 0 8px 8px 0" />
                                    <a-button type="link" @click="NewDeleteItem(index,it,item.SelectedList)">删除</a-button>
                                    <div v-if="index / 3 ==0 && index!=0 "></div>
                                </span>
                                <!--<a-button type="primary"
                                      @click="NewAddSpecification(item.defaultValue,item.SelectedList)"
                                      style="margin-left: 20px"
                                      icon="plus"></a-button>-->
                            </a-layout-content>
                            <!-- <div  style="height:2px;background:#ddd;width:90%;margin:0 auto"></div> -->

                        </a-layout>
                        <a-layout-footer style="background: #ffffff;">
                            <div style="padding-left: 150px;">
                                <a-button type="primary" v-on:click="AddGg()" icon="plus"> 增加规格 </a-button>
                            </div>
                        </a-layout-footer>
                    </a-card>
                    <br />
                    <a-card title="扩展信息"
                            :bordered="false"
                            v-if="ProductType == 2 || ProductType == 1">
                        <a-row style="width: 640px" class="yj-produ-list">
                            <a-col :span="24" style="line-height: 40px">
                                <a-col :span="5" class="textal_r">
                                    <label style="width: 100%">显示{{ Place }}位置 :</label>
                                </a-col>
                                <a-col :span="18" :offset="1">
                                    <a-form-model-item has-feedback prop="IsShowMap">
                                        <a-switch v-model="FormModel.IsShowMap"
                                                  :disabled="disabled">
                                            <a-icon slot="checkedChildren" type="check" />
                                            <a-icon slot="unCheckedChildren" type="close" />
                                        </a-switch>
                                    </a-form-model-item>
                                </a-col>
                            </a-col>
                            <div v-if="FormModel.IsShowMap == true">
                                <a-col :span="24" style="line-height: 40px">
                                    <a-col :span="5" class="textal_r">
                                        <label style="width: 100%">{{ Place }}位置 :</label>
                                    </a-col>
                                    <a-col :span="18" :offset="1">
                                        <a @click="AddRegistration" v-if="plainOptions.length == 0">去添加</a>
                                        <a-form-model-item has-feedback
                                                           prop="ProductTrainPlace"
                                                           v-if="plainOptions.length > 0">
                                            <template>
                                                <a-checkbox-group :options="plainOptions"
                                                                  v-model="ProductTrainPlace"
                                                                  :disabled="disabled" />
                                            </template>
                                        </a-form-model-item>
                                    </a-col>
                                </a-col>
                            </div>
                        </a-row>
                    </a-card>
                    <br />
                    <a-card title="购买者填写信息"
                            :bordered="false"
                            v-if="ProductType == 2 || ProductType == 1">
                        <a-form-model-item has-feedback
                                           label="购买者填写信息:"
                                           style="display: none">
                            <a-switch v-model="Purchasershows"
                                      @change="PurchaserChange"
                                      :disabled="disabled">
                                <a-icon slot="checkedChildren" type="check" />
                                <a-icon slot="unCheckedChildren" type="close" />
                            </a-switch>
                        </a-form-model-item>
                        <a-form-model-item has-feedback
                                           prop="PurchaserName"
                                           label="姓名 :"
                                           v-if="Purchasershows == true">
                            <a-radio-group v-model="FormModel.PurchaserName"
                                           :disabled="disabled">
                                <a-radio :value="0"> 不填 </a-radio>
                                <a-radio :value="1"> 填写 </a-radio>
                                <a-radio :value="2"> 必填 </a-radio>
                                <a-radio :value="3"> 唯一 </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item has-feedback
                                           prop="Phone"
                                           label="手机 :"
                                           v-if="Purchasershows == true">
                            <a-radio-group v-model="FormModel.Phone" :disabled="disabled">
                                <a-radio :value="0"> 不填 </a-radio>
                                <a-radio :value="1"> 填写 </a-radio>
                                <a-radio :value="2"> 必填 </a-radio>
                                <a-radio :value="3"> 唯一 </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item has-feedback
                                           prop="IDCard"
                                           label="身份证 :"
                                           v-if="Purchasershows == true">
                            <a-radio-group v-model="FormModel.IDCard" :disabled="disabled">
                                <a-radio :value="0"> 不填 </a-radio>
                                <a-radio :value="1"> 填写 </a-radio>
                                <a-radio :value="2"> 必填 </a-radio>
                                <a-radio :value="3"> 唯一 </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item has-feedback
                                           prop="IDUpload"
                                           label="身份证上传 :"
                                           style="display: none">
                            <a-radio-group v-model="FormModel.IDUpload" :disabled="disabled">
                                <a-radio :value="0"> 不填 </a-radio>
                                <a-radio :value="1"> 填写 </a-radio>
                                <a-radio :value="2"> 必填 </a-radio>
                                <a-radio :value="3"> 唯一 </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item has-feedback
                                           prop="Address"
                                           label="家庭住址 :"
                                           v-if="Purchasershows == true">
                            <a-radio-group v-model="FormModel.Address" :disabled="disabled">
                                <a-radio :value="0"> 不填 </a-radio>
                                <a-radio :value="1"> 填写 </a-radio>
                                <a-radio :value="2"> 必填 </a-radio>
                                <a-radio :value="3"> 唯一 </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-card>
                </div>
                <div class="steps-action" v-if="disabled == false">
                    <br />
                    <a-card>
                        <a-button class="margin-r"
                                  style="margin-left: 60px"
                                  @click="submitForm('ruleForm')">
                            保存
                        </a-button>
                        <!--<a-button type="primary" @click="submitForm('ruleForm',1)">
                                        保存并编辑佣金
                                    </a-button>-->
                        <a-button type="primary" @click="EditCommission(1)">
                            保存并编辑佣金
                        </a-button>
                    </a-card>
                </div>
            </div>
            <div v-if="baseinformation == false">
                <a-card title="基本信息" :bordered="false">
                    <a-form-model-item has-feedback label="开启佣金:">
                        <a-switch v-model="Commisionshows"
                                  @change="CommisionChange"
                                  :disabled="disabled">
                            <a-icon slot="checkedChildren" type="check" />
                            <a-icon slot="unCheckedChildren" type="close" />
                        </a-switch>
                    </a-form-model-item>
                    <div v-if="Commisionshows == true && FormModel.CommissionPlus == 0">
                        <a-form-model-item has-feedback
                                           prop="SellCommision"
                                           :label="ShowName[0]">
                            <a-input-number :min="0"
                                            v-model="FormModel.SellCommision"
                                            :step="0.01"
                                            :disabled="disabled"
                                            :precision="2" />
                        </a-form-model-item>
                        <a-form-model-item has-feedback
                                           prop="FirstCommision"
                                           :label="ShowName[1]">
                            <a-input-number :min="0"
                                            v-model="FormModel.FirstCommision"
                                            :step="0.01"
                                            :disabled="disabled"
                                            :precision="2" />
                        </a-form-model-item>
                        <a-form-model-item has-feedback
                                           prop="SecondCommision"
                                           :label="ShowName[2]">
                            <a-input-number :min="0"
                                            v-model="FormModel.SecondCommision"
                                            :step="0.01"
                                            :disabled="disabled"
                                            :precision="2" />
                        </a-form-model-item>
                        <a-form-model-item has-feedback label="顶级团队:">
                            <a-switch v-model="IsSendTop" :disabled="disabled">
                                <a-icon slot="checkedChildren" type="check" />
                                <a-icon slot="unCheckedChildren" type="close" />
                            </a-switch><label style="margin-left: 50px">(当佣金发放对象是顶级团队时，是否发放佣金)</label>
                        </a-form-model-item>
                        <a-form-model-item has-feedback prop="Remarks" label="说明 :">
                            <label v-html="Remarks"></label>
                            <a-button type="primary"
                                      @click="changeCommissionPlus('1')"
                                      :disabled="disabled">
                                高级选项
                            </a-button>
                        </a-form-model-item>
                    </div>
                    <div v-if="Commisionshows == true && FormModel.CommissionPlus == 1">
                        <a-form-model-item has-feedback prop="MappingID" label="方案 :">
                            <a-radio-group v-model="FormModel.MappingID"
                                           :disabled="disabled"
                                           :options="Commisionoptions"
                                           @change="Commisionoptionschange">
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item has-feedback label="顶级团队:">
                            <a-switch v-model="CommissionIsSendTop" :disabled="disabled">
                                <a-icon slot="checkedChildren" type="check" />
                                <a-icon slot="unCheckedChildren" type="close" />
                            </a-switch><label style="margin-left: 50px">(当佣金发放对象是顶级团队时，是否发放佣金)</label>
                        </a-form-model-item>
                        <a-form-model-item has-feedback prop="Remarks" label="说明 :">
                            <label v-html="Remarks"></label>
                            <a-button type="primary"
                                      @click="changeCommissionPlus('0')"
                                      :disabled="disabled">
                                恢复默认
                            </a-button>
                        </a-form-model-item>
                        <a-form-model-item has-feedback label="总额限制:">
                            <a-switch v-model="Limitshows"
                                      @change="LimitChange"
                                      :disabled="disabled">
                                <a-icon slot="checkedChildren" type="check" />
                                <a-icon slot="unCheckedChildren" type="close" />
                            </a-switch>
                        </a-form-model-item>
                        <a-form-model-item has-feedback
                                           prop="Moneys"
                                           label="总额:"
                                           v-if="Limitshows == true">
                            <a-input-number :min="0"
                                            :step="0.01"
                                            v-model="FormModel.Moneys"
                                            :disabled="disabled"
                                            :precision="2" />
                        </a-form-model-item>
                    </div>
                </a-card>
                <div v-if="Commisionshows == true && FormModel.CommissionPlus == 1">
                    <a-card :title="ShowName[0]" :bordered="false">
                        <a-form-model-item has-feedback prop="Mode0" label="计算方式:">
                            <a-radio-group v-model="CommissionDetails[0].Mode"
                                           :disabled="disabled">
                                <a-radio :value="0"> 定值 </a-radio>
                                <a-radio :value="1"> 比例 </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item has-feedback
                                           prop="Moneys0"
                                           label="定值:"
                                           v-if="CommissionDetails[0].Mode == 0">
                            <a-input-number :min="0"
                                            :step="0.01"
                                            v-model="CommissionDetails[0].Moneys"
                                            :disabled="disabled"
                                            :precision="2" />
                        </a-form-model-item>
                        <div v-if="CommissionDetails[0].Mode == 1">
                            <a-form-model-item has-feedback prop="Base0" label="基数:">
                                <a-radio-group v-model="CommissionDetails[0].Base"
                                               :disabled="disabled">
                                    <a-radio :value="0"> 付款金额 </a-radio>
                                    <a-radio :value="1"> 订单价格 </a-radio>
                                </a-radio-group>
                            </a-form-model-item>
                            <a-form-model-item has-feedback prop="Proportion0" label="比例:">
                                <a-input-number :min="0"
                                                :step="0.01"
                                                v-model="CommissionDetails[0].Proportion"
                                                :disabled="disabled"
                                                :precision="2" />
                            </a-form-model-item>
                            <a-form-model-item has-feedback
                                               prop="CommissionDetailsMaxSwitch0"
                                               label="限制最大值:">
                                <a-switch v-model="CommissionDetails[0].MaxSwitch"
                                          :disabled="disabled">
                                    <a-icon slot="checkedChildren" type="check" />
                                    <a-icon slot="unCheckedChildren" type="close" />
                                </a-switch>
                            </a-form-model-item>
                            <a-form-model-item has-feedback
                                               prop="CommissionDetailsMaxMoneys0"
                                               label="最大值:"
                                               v-if="CommissionDetails[0].MaxSwitch == true">
                                <a-input-number :min="0"
                                                :step="0.01"
                                                v-model="CommissionDetails[0].MaxMoneys"
                                                :disabled="disabled"
                                                :precision="2" />
                            </a-form-model-item>
                            <a-form-model-item has-feedback
                                               prop="CommissionDetailsMinSwitch0"
                                               label="限制最小值:">
                                <a-switch v-model="CommissionDetails[0].MinSwitch"
                                          :disabled="disabled">
                                    <a-icon slot="checkedChildren" type="check" />
                                    <a-icon slot="unCheckedChildren" type="close" />
                                </a-switch>
                            </a-form-model-item>
                            <a-form-model-item has-feedback
                                               prop="CommissionDetailsMinMoneys0"
                                               label="最小值:"
                                               v-if="CommissionDetails[0].MinSwitch == true">
                                <a-input-number :min="0"
                                                :step="0.01"
                                                v-model="CommissionDetails[0].MinMoneys"
                                                :disabled="disabled"
                                                :precision="2" />
                            </a-form-model-item>
                        </div>
                    </a-card>
                    <a-card :title="ShowName[1]" :bordered="false">
                        <a-form-model-item has-feedback prop="Mode1" label="计算方式:">
                            <a-radio-group v-model="CommissionDetails[1].Mode"
                                           :disabled="disabled">
                                <a-radio :value="0"> 定值 </a-radio>
                                <a-radio :value="1"> 比例 </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item has-feedback
                                           prop="Moneys1"
                                           label="定值:"
                                           v-if="CommissionDetails[1].Mode == 0">
                            <a-input-number :min="0"
                                            :step="0.01"
                                            v-model="CommissionDetails[1].Moneys"
                                            :disabled="disabled"
                                            :precision="2" />
                        </a-form-model-item>
                        <div v-if="CommissionDetails[1].Mode == 1">
                            <a-form-model-item has-feedback prop="Base1" label="基数:">
                                <a-radio-group v-model="CommissionDetails[1].Base"
                                               :disabled="disabled">
                                    <a-radio :value="0"> 付款金额 </a-radio>
                                    <a-radio :value="1"> 订单价格 </a-radio>
                                </a-radio-group>
                            </a-form-model-item>
                            <a-form-model-item has-feedback prop="Proportion1" label="比例:">
                                <a-input-number :min="0"
                                                :step="0.01"
                                                v-model="CommissionDetails[1].Proportion"
                                                :disabled="disabled"
                                                :precision="2" />
                            </a-form-model-item>
                            <a-form-model-item has-feedback
                                               prop="CommissionDetailsMaxSwitch1"
                                               label="限制最大值:">
                                <a-switch v-model="CommissionDetails[1].MaxSwitch"
                                          :disabled="disabled">
                                    <a-icon slot="checkedChildren" type="check" />
                                    <a-icon slot="unCheckedChildren" type="close" />
                                </a-switch>
                            </a-form-model-item>
                            <a-form-model-item has-feedback
                                               prop="CommissionDetailsMaxMoneys1"
                                               label="最大值:"
                                               v-if="CommissionDetails[1].MaxSwitch == true">
                                <a-input-number :min="0"
                                                :step="0.01"
                                                v-model="CommissionDetails[1].MaxMoneys"
                                                :disabled="disabled"
                                                :precision="2" />
                            </a-form-model-item>
                            <a-form-model-item has-feedback
                                               prop="CommissionDetailsMinSwitch1"
                                               label="限制最小值:">
                                <a-switch v-model="CommissionDetails[1].MinSwitch"
                                          :disabled="disabled">
                                    <a-icon slot="checkedChildren" type="check" />
                                    <a-icon slot="unCheckedChildren" type="close" />
                                </a-switch>
                            </a-form-model-item>
                            <a-form-model-item has-feedback
                                               prop="CommissionDetailsMinMoneys1"
                                               label="最小值:"
                                               v-if="CommissionDetails[1].MinSwitch == true">
                                <a-input-number :min="0"
                                                :step="0.01"
                                                v-model="CommissionDetails[1].MinMoneys"
                                                :disabled="disabled"
                                                :precision="2" />
                            </a-form-model-item>
                        </div>
                    </a-card>
                    <a-card :title="ShowName[2]" :bordered="false">
                        <a-form-model-item has-feedback prop="Mode2" label="计算方式:">
                            <a-radio-group v-model="CommissionDetails[2].Mode"
                                           :disabled="disabled">
                                <a-radio :value="0"> 定值 </a-radio>
                                <a-radio :value="1"> 比例 </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item has-feedback
                                           prop="Moneys2"
                                           label="定值:"
                                           v-if="CommissionDetails[2].Mode == 0">
                            <a-input-number :min="0"
                                            :step="0.01"
                                            v-model="CommissionDetails[2].Moneys"
                                            :disabled="disabled"
                                            :precision="2" />
                        </a-form-model-item>
                        <div v-if="CommissionDetails[2].Mode == 1">
                            <a-form-model-item has-feedback prop="Base2" label="基数:">
                                <a-radio-group v-model="CommissionDetails[2].Base"
                                               :disabled="disabled">
                                    <a-radio :value="0"> 付款金额 </a-radio>
                                    <a-radio :value="1"> 订单价格 </a-radio>
                                </a-radio-group>
                            </a-form-model-item>
                            <a-form-model-item has-feedback prop="Proportion2" label="比例:">
                                <a-input-number :min="0"
                                                :step="0.01"
                                                v-model="CommissionDetails[2].Proportion"
                                                :disabled="disabled"
                                                :precision="2" />
                            </a-form-model-item>
                            <a-form-model-item has-feedback
                                               prop="CommissionDetailsMaxSwitch2"
                                               label="限制最大值:">
                                <a-switch v-model="CommissionDetails[2].MaxSwitch"
                                          :disabled="disabled">
                                    <a-icon slot="checkedChildren" type="check" />
                                    <a-icon slot="unCheckedChildren" type="close" />
                                </a-switch>
                            </a-form-model-item>
                            <a-form-model-item has-feedback
                                               prop="CommissionDetailsMaxMoneys2"
                                               label="最大值:"
                                               v-if="CommissionDetails[2].MaxSwitch == true">
                                <a-input-number :min="0"
                                                :step="0.01"
                                                v-model="CommissionDetails[2].MaxMoneys"
                                                :disabled="disabled"
                                                :precision="2" />
                            </a-form-model-item>
                            <a-form-model-item has-feedback
                                               prop="CommissionDetailsMinSwitch2"
                                               label="限制最小值:">
                                <a-switch v-model="CommissionDetails[2].MinSwitch"
                                          :disabled="disabled">
                                    <a-icon slot="checkedChildren" type="check" />
                                    <a-icon slot="unCheckedChildren" type="close" />
                                </a-switch>
                            </a-form-model-item>
                            <a-form-model-item has-feedback
                                               prop="CommissionDetailsMinMoneys2"
                                               label="最小值:"
                                               v-if="CommissionDetails[2].MinSwitch == true">
                                <a-input-number :min="0"
                                                :step="0.01"
                                                v-model="CommissionDetails[2].MinMoneys"
                                                :disabled="disabled"
                                                :precision="2" />
                            </a-form-model-item>
                        </div>
                    </a-card>
                </div>
                <div v-if="disabled == false">
                    <a-button class="margin-r"
                              style="margin-left: 60px"
                              @click="edit('ruleForm')">
                        编辑详情
                    </a-button>
                    <a-button type="primary" @click="submitcommission('ruleForm')">
                        保存
                    </a-button>
                </div>
            </div>
        </a-form-model>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import imagedata from "@/Plugin/UploadDataHelper.js";
    import Rich from "@/components/RichEditor.vue";
    export default {
        name: "",
        data() {
            return {
                FormModel: {},
                rules: {
                    Name: [
                        { validator: this.validateName, trigger: "change", required: true },
                    ],
                    AdvanceMoney: [
                        { validator: this.validateAdvanceMoney, trigger: "change" },
                    ],
                    SellCommision: [
                        { validator: this.validateCommision, trigger: "change" },
                    ],
                    FirstCommision: [
                        { validator: this.validateCommision, trigger: "change" },
                    ],
                    SecondCommision: [
                        { validator: this.validateCommision, trigger: "change" },
                    ],
                },
                cars: [],
                Intended: "",
                Intendedcourse: "",
                teams: [],
                course: [],
                ParamData: {
                    ProcessorName: "Image",
                    JsonDataParams: "",
                },
                Advanceshows: false,
                Purchasershows: true,
                Commisionshows: false,
                IsSendTop: false,
                baseinformation: true,
                ProductID: 0,
                plainOptions: [],
                ProductTrainPlace: [],
                disabled: false,
                carsdisabled: [],
                teamsdisabled: [],
                coursedisabled: [],
                ProductType: 1,
                labelCol: { span: 2 },
                wrapperCol: { span: 14 },
                Place: "训练场",
                Commisionoptions: [],
                Remarks: "",
                Limitshows: false,
                ShowName: [],
                CommissionDetails: [{ Mode: 0 }, { Mode: 0 }, { Mode: 0 }],
                CommissionIsSendTop: false,
                Advancedisabled: true,
                OriginalMoneyShow: false,
                M2ActivityID: 0,
                ReturnUrl: "ProductList",
                Inventory: 0,
                CommissionID: 0,
                SelectData: [], //规格数据
                GoodsSpecificationList: [],//规格数据列表
                OneGoodsSpecifiaction: [],
                TwoGoodsSpecifiaction: [],
                NewSpecifiationDetailInfo: [],
                NewSpecifiationDetailInfoTemp: [],
                GgOneID: "",
                GgTwoID: "",
                CustomSpecificationData: [],
                visible: false, //显示添加对话框
                confirmLoading: false, //按钮加载中样式
                SFName: null,
                GroupSepiactionName: [],
                NewSFName: "",

            };
        },
        props: {}, //组件属性
        methods: {
            //显示新增规格列表
            ShowAddSpecification() {
                this.visible = true;
            },
            //单击Ok键
            handleOk(index, SList) {
                var self = this;
                //console.log(self.SFName);
                if (self.SFName == null) {
                    self.$message.warning("导入名称不能为空!");
                    return;
                }
                else {
                    self.AddSpecificationGroup(index, SList);
                    self.confirmLoading = false;
                    self.visible = false;
                    self.SFName = null;
                }

                console.log("self.GoodsSpecificationList", self.GoodsSpecificationList);
            },
            //单击取消
            handleCancel() {
                this.confirmLoading = false;
                this.visible = false;
                this.SFName = null;
            },
            //导入
            AddSpecificationGroup(index, SList) {
                var self = this;
                //console.log("self.SFName.split(';').length", self.SFName.split(';'));

                self.NewSFName = self.SFName.replace(/；/g, ";");
                //console.log("NewSFName", self.NewSFName);


                self.GroupSepiactionName = self.NewSFName.split(';');

                //console.log(" self.GroupSepiactionName", self.GroupSepiactionName);

                var Newsf = self.NewSFName.slice(0, self.NewSFName.length - 1);

                //console.log("Newsf", Newsf);

                for (var o = 0; o < Newsf.split(';').length; o++) {
                    var temps = {
                        ID: SList.length + 1,
                        Name: self.GroupSepiactionName[o],
                        ProductID: null,
                        GgID: index,
                        PSFid: null,
                        PSFDetailID: null,
                    };
                    temps.ProductID = (this.ProductID && this.ProductID) || null;

                    SList.push(temps);
                    //this.confirmLoading = false;
                }
                console.log("self.GoodsSpecificationList", self.GoodsSpecificationList);
            },


            GetCustomSpecificationData() {
                var self = this;
                var op = {
                    url: "/ShopModule/Product/GetCustomSpecificationData",
                    data: {
                    },
                    OnSuccess: function (data) {
                        console.log("dataaaaaaaaaaa", data);
                        self.CustomSpecificationData = data.data;
                    },
                };
                http.Post(op);

            },


            AddGg() {
                var self = this;
                //console.log("添加规格");
                if (self.SelectData.length == 0)
                    return;
                var item = {
                    defaultValue: this.SelectData[0].ID,
                    SelectData: this.SelectData,
                    SelectedList: [],
                };
                //console.log("Typeeeeeeee", Type);
                //console.log("itemmmmmmmmmm", item);
              
                this.GoodsSpecificationList.push(item);
            },
            NewDeleteItem(index, it, Slist) {
                Slist.splice(index,1);
            },
            NewAddSpecification(index, SList) {
                //console.log(index, SList);
                var temp = {
                    ID: SList.length + 1,
                    Name: null,
                    ProductID: null,
                    GgID: index,
                    PSFid: null,
                    PSFDetailID: null,
                };
                temp.ProductID = (this.ProductID && this.ProductID) || null;
                SList.push(temp);
            },
            NewDelSpecification(index) {
                this.GoodsSpecificationList.splice(index, 1)
            },
            NewGgChangeOne(e, item) {
                item.defaultValue = e;
                item.SelectedList = [];
                console.log(e, item);
            },
            AddSpecification(Type) {
                console.log("类别", Type);
                if (this.SelectData.length <= 0) return;
                var item = {
                    ID: 0,
                    Name: null,
                    ProductID: null,
                    GgID: null,
                    PSFid: null,
                    PSFDetailID: null,
                };
                item.ProductID = (this.ProductID && this.ProductID) || null;
                item.ID =
                    (Type && this.OneGoodsSpecifiaction.length + 1) ||
                    this.TwoGoodsSpecifiaction.length + 1;
                item.GgID = (Type && this.GgOneID) || this.GgTwoID;
                (Type && (this.OneGoodsSpecifiaction.push(item), 1)) ||
                    this.TwoGoodsSpecifiaction.push(item);
                console.log(item);
            },
            GgChangeOne(e) {
                this.GgOneID = e;
                this.OneGoodsSpecifiaction = [];
                console.log(e);
            },
            GgChangeTwo(e) {
                this.GgTwoID = e;
                this.TwoGoodsSpecifiaction = [];
                console.log(e);
            },
            GetSpecification() {
                var self = this;
                var op = {
                    url: "/ShopModule/Product/Select_SpecificationList",
                    OnSuccess: function (res) {
                        self.SelectData = res.data;
                        res.data && res.data[0] && ((self.GgOneID = res.data[0].ID), 1);
                        res.data && res.data[1] && ((self.GgTwoID = res.data[1].ID), 1);
                    },
                };
                http.Post(op);
            },

            GetSpectionNew(pid) {

                var self = this;
                var op = {
                    url: "/ShopModule/Product/Select_SpecificationList",
                    OnSuccess: function (res) {
                        self.SelectData = res.data;
                        //res.data && res.data[0] && ((self.GgOneID = res.data[0].ID), 1);
                        //res.data && res.data[1] && ((self.GgTwoID = res.data[1].ID), 1);
                        self.getProductSpecification(pid);
                    },
                };
                http.Post(op);
            },


            DeleteItem(id, Type) {
                console.log(id + "||" + Type);
                var Datas =
                    (Type && this.OneGoodsSpecifiaction) || this.TwoGoodsSpecifiaction;
                var bo = false;
                var index = 0;
                for (var i = 0; i < Datas.length; i++) {
                    Datas[i].ID == id && ((bo = true), (index = i));
                    if (bo) break;
                }
                (bo && Type && (this.OneGoodsSpecifiaction.splice(index, 1), 1)) ||
                    this.TwoGoodsSpecifiaction.splice(index, 1);
            },
            EditCommission(type) {
                var self = this;
             
                if (self.checkProductExImage()) {

                        var SePifiactionList = [];
                        for (var i = 0; i < self.GoodsSpecificationList.length; i++) {
                            if (self.GoodsSpecificationList[i].SelectedList.length > 0) {
                                for (var j = 0; j < self.GoodsSpecificationList[i].SelectedList.length; j++) {
                                    SePifiactionList.push(self.GoodsSpecificationList[i].SelectedList[j]);
                                }
                            } else {
                                self.$message.error("请填写具体规格信息");
                                return;
                            }
                            for (var q = 0; q < SePifiactionList.length; q++) {
                                if (SePifiactionList[q]["Name"] == null || SePifiactionList[q]["Name"] == "") {
                                    self.$message.error("规格名称不允许为空");
                                    return;
                                }
                            }
                        }
                        console.log("QQQQQQQQQQ", SePifiactionList);

                        if (type == 1) {
                            self.baseinformation = false;
                        } else {
                            self.push();
                        }
                        var op1 = {
                            url: "/ShopModule/Product/Save",
                            data: {
                                ID: self.ProductID,
                                Name: self.FormModel.Name,
                                ProductType: self.ProductType,
                                CustomID: self.FormModel.CustomID,
                                ShowStatus: self.FormModel.ShowStatus,
                                BuyMoneyType: self.Advanceshows,
                                ProductExParameter: self.getProductExParameter(),
                                Summary: self.FormModel.Summary,
                                SaleMoney: self.FormModel.SaleMoney,
                                OriginalMoney: self.FormModel.OriginalMoney,
                                AdvanceMoney: self.FormModel.AdvanceMoney,
                                BuyCountShow: self.FormModel.BuyCountShow,
                                ProductExImage: self.getProductExImage(),
                                ContentText: self.FormModel.ContentText,
                                ProductTrainPlace: self.getProductTrainPlace(),
                                IsShowMap: self.FormModel.IsShowMap,
                                Purchaser: self.Purchasershows,
                                PurchaserName: self.FormModel.PurchaserName,
                                Phone: self.FormModel.Phone,
                                IDCard: self.FormModel.IDCard,
                                IDUpload: self.FormModel.IDUpload,
                                Address: self.FormModel.Address,
                                Inventory: self.Inventory,
                                SFData: SePifiactionList,
                            },
                            OnSuccess: function (response) {
                                self.ProductID = response.data;
                                console.log("self.ProductID", self.ProductID);
                                //佣金
                                var op = {
                                    url: "/ShopModule/Product/SaveCommissionInfo",
                                    data: {
                                        ID: self.ProductID,
                                        Commision: self.Commisionshows,
                                        SellCommision: self.FormModel.SellCommision,
                                        FirstCommision: self.FormModel.FirstCommision,
                                        SecondCommision: self.FormModel.SecondCommision,
                                        IsSendTop: self.IsSendTop,
                                        CommissionIsSendTop: self.CommissionIsSendTop == true ? 1 : 0,
                                        MappingID: self.FormModel.MappingID,
                                        Limit: self.Limitshows == true ? 1 : 0,
                                        Moneys: self.FormModel.Moneys,
                                        CommissionDetails:
                                            self.getCommission() == null
                                                ? self.CommissionDetails
                                                : [
                                                    { Mode: 0, Proportion: 33.33, Orders: 1 },
                                                    { Mode: 0, Proportion: 33.33, Orders: 2 },
                                                    { Mode: 0, Proportion: 33.33, Orders: 3 },
                                                ],
                                        CommissionPlus: self.FormModel.CommissionPlus,
                                        OriginalMoney: self.FormModel.OriginalMoney,
                                        M2ActivityID: self.M2ActivityID,
                                        CommissionID: self.CommissionID,
                                    },
                                    OnSuccess: function (response) {
                                        self.CommissionID = response.data;
                                        self.$message.success("保存成功", 1.5, function () { });
                                    },
                                };
                                http.Post(op);
                            },
                        };
                        http.Post(op1);

                  
                } else {
                    return false;
                }
            },

            //保存商品
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        var self = this;
                        var SePifiactionList = [];
                        //if (self.GoodsSpecificationList.length > 0) {
                        
                        for (var i = 0; i < self.GoodsSpecificationList.length; i++) {
                            console.log("self.GoodsSpecificationList[i].SelectedList.length", self.GoodsSpecificationList[i].SelectedList.length);
                            if (self.GoodsSpecificationList[i].SelectedList.length > 0) {
                                for (var j = 0; j < self.GoodsSpecificationList[i].SelectedList.length; j++) {
                                    SePifiactionList.push(self.GoodsSpecificationList[i].SelectedList[j]);
                                }
                            }
                            else {
                                self.$message.error("请填写具体规格信息");
                                return;
                            }
                            //console.log("SePifiactionList", SePifiactionList[i]);
                            //console.log("SePifiactionList.Name", SePifiactionList[i]["Name"]);
                            for (var q = 0; q < SePifiactionList.length; q++) {
                                if (SePifiactionList[q]["Name"] == null || SePifiactionList[q]["Name"]=="") {
                                    self.$message.error("规格名称不允许为空");
                                    return;
                                }
                            }

                        }
                            console.log("QQQQQQQQQQ", SePifiactionList);
                            if (self.checkProductExImage()) {
                                var op = {
                                    url: "/ShopModule/Product/Save",
                                    data: {
                                        ID: self.ProductID,
                                        Name: self.FormModel.Name,
                                        ProductType: self.ProductType,
                                        CustomID: self.FormModel.CustomID,
                                        ShowStatus: self.FormModel.ShowStatus,
                                        BuyMoneyType: self.Advanceshows,
                                        ProductExParameter: self.getProductExParameter(),
                                        Summary: self.FormModel.Summary,
                                        SaleMoney: self.FormModel.SaleMoney,
                                        AdvanceMoney: self.FormModel.AdvanceMoney,
                                        BuyCountShow: self.FormModel.BuyCountShow,
                                        ProductExImage: self.getProductExImage(),
                                        ContentText: self.FormModel.ContentText,
                                        ProductTrainPlace: self.getProductTrainPlace(),
                                        IsShowMap: self.FormModel.IsShowMap,
                                        Purchaser: self.Purchasershows,
                                        PurchaserName: self.FormModel.PurchaserName,
                                        Phone: self.FormModel.Phone,
                                        IDCard: self.FormModel.IDCard,
                                        IDUpload: self.FormModel.IDUpload,
                                        Address: self.FormModel.Address,
                                        Commision: self.Commisionshows,
                                        SellCommision: self.FormModel.SellCommision,
                                        FirstCommision: self.FormModel.FirstCommision,
                                        SecondCommision: self.FormModel.SecondCommision,
                                        IsSendTop: self.IsSendTop,
                                        CommissionIsSendTop: self.CommissionIsSendTop == true ? 1 : 0,
                                        MappingID: self.FormModel.MappingID,
                                        Limit: self.Limitshows == true ? 1 : 0,
                                        Moneys: self.FormModel.Moneys,
                                        CommissionDetails:
                                            self.getCommission() == null
                                                ? self.CommissionDetails
                                                : [
                                                    { Mode: 0, Proportion: 33.33, Orders: 1 },
                                                    { Mode: 0, Proportion: 33.33, Orders: 2 },
                                                    { Mode: 0, Proportion: 33.33, Orders: 3 },
                                                ],
                                        CommissionPlus: self.FormModel.CommissionPlus,
                                        OriginalMoney: self.FormModel.OriginalMoney,
                                        M2ActivityID: self.M2ActivityID,
                                        Inventory: self.Inventory,
                                        CommissionID: self.CommissionID,
                                        SFData: SePifiactionList,
                                    },
                                    OnSuccess: function (response) {
                                        console.log(formName + response);
                                        self.ProductID = response.data;
                                        self.$message.success("保存成功", 1.5, function () { });
                                        self.push();
                                        //佣金
                                        var op1 = {
                                            url: "/ShopModule/Product/SaveCommissionInfo",
                                            data: {
                                                ID: self.ProductID,
                                                Commision: self.Commisionshows,
                                                SellCommision: self.FormModel.SellCommision,
                                                FirstCommision: self.FormModel.FirstCommision,
                                                SecondCommision: self.FormModel.SecondCommision,
                                                IsSendTop: self.IsSendTop,
                                                CommissionIsSendTop:
                                                    self.CommissionIsSendTop == true ? 1 : 0,
                                                MappingID: self.FormModel.MappingID,
                                                Limit: self.Limitshows == true ? 1 : 0,
                                                Moneys: self.FormModel.Moneys,
                                                CommissionDetails:
                                                    self.getCommission() == null
                                                        ? self.CommissionDetails
                                                        : [
                                                            { Mode: 0, Proportion: 33.33, Orders: 1 },
                                                            { Mode: 0, Proportion: 33.33, Orders: 2 },
                                                            { Mode: 0, Proportion: 33.33, Orders: 3 },
                                                        ],
                                                CommissionPlus: self.FormModel.CommissionPlus,
                                                OriginalMoney: self.FormModel.OriginalMoney,
                                                M2ActivityID: self.M2ActivityID,
                                                CommissionID: self.CommissionID,
                                            },
                                            OnSuccess: function (response) {
                                                self.CommissionID = response.data;
                                            },
                                        };
                                        http.Post(op1);

                                        //if (type == 1) {
                                        //    self.baseinformation = false;
                                        //}
                                        //else {
                                        //    self.push();
                                        //}
                                    },
                                };
                                http.Post(op);
                            }

                       // }
                     
                    } else {
                        return false;
                    }
                });
            },
            push() {
                var self = this;
                if (self.ReturnUrl.indexOf("?") >= 0) {
                    var query = {};
                    var querylist = self.ReturnUrl.split("?")[1].split("|");
                    querylist.forEach((e) => {
                        query[e.split("=")[0]] = e.split("=")[1];
                    });
                    self.$router.push({
                        name: self.ReturnUrl.split("?")[0],
                        query: query,
                    });
                } else {
                    self.$router.push({ name: self.ReturnUrl });
                }
            },
            commission(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        var self = this;
                        if (self.checkMoneys()) {
                            var op = {
                                url: "/ShopModule/Product/UpdateCommision",
                                data: {
                                    ID: self.ProductID,
                                    Commision: self.Commisionshows,
                                    SellCommision: self.FormModel.SellCommision,
                                    FirstCommision: self.FormModel.FirstCommision,
                                    SecondCommision: self.FormModel.SecondCommision,
                                    IsSendTop: self.IsSendTop,
                                    CommissionIsSendTop: self.CommissionIsSendTop == true ? 1 : 0,
                                    CommissionID: self.FormModel.CommissionID,
                                    MappingID: self.FormModel.MappingID,
                                    Limit: self.Limitshows == true ? 1 : 0,
                                    Moneys: self.FormModel.Moneys,
                                    CommissionPlus: self.FormModel.CommissionPlus,
                                    CommissionDetails:
                                        self.getCommission() /* == null ? self.CommissionDetails : [{ "Mode": 0,"Proportion":33.33,"Orders":1 }, { "Mode": 0,"Proportion":33.33,"Orders":2 }, { "Mode": 0,"Proportion":33.33,"Orders":3 }]*/,
                                    Inventory: self.Inventory,
                                },
                                OnSuccess: function (response) {
                                    console.log(formName + response);
                                    self.$message.success("保存成功", 1.5, function () { });
                                    self.push();
                                },
                            };
                            http.Post(op);
                        }
                    } else {
                        return false;
                    }
                });
            },
            submitcommission(formName) {
                var self = this;
                var moneyb = true;
                var money = 0;
                self.CommissionDetails.forEach((e) => {
                    if (e.Mode == 0) {
                        money += e.Moneys;
                    } else {
                        moneyb = false;
                    }
                });
                if (moneyb && self.Limitshows && money > self.FormModel.Moneys) {
                    this.$confirm({
                        title: "提示",
                        content: "定值金额超过限制总额，是否继续",
                        okText: "确定",
                        cancelText: "取消",
                        onOk() {
                            self.commission(formName);
                        },
                        onCancel() { },
                    });
                } else {
                    self.commission(formName);
                }
            },
            edit(formName) {
                console.log(formName);
                var self = this;
                self.baseinformation = true;
            },
            validateName: function (rule, value, callback) {
                if (value === "" || value === undefined) {
                    callback(new Error("请输入名称"));
                } else {
                    callback();
                }
            },
            validateAdvanceMoney: function (rule, value, callback) {
                var self = this;
                if (self.Advanceshows) {
                    if (value === "" || value === undefined || value === null) {
                        callback(new Error("请输入预付款"));
                    } else {
                        callback();
                    }
                }
            },
            validateCommision: function (rule, value, callback) {
                var self = this;
                if (self.Commisionshows) {
                    if (value === "" || value === undefined || value === null) {
                        callback(new Error("请输入佣金"));
                    } else {
                        callback();
                    }
                }
            },
            getTableData: function (id) {
                var self = this;
                var op = {
                    url: "/ShopModule/Product/GetProductConfigs",
                    data: {
                        productType: self.ProductType,
                    },
                    OnSuccess: function (data) {
                        data.data.forEach((e) => {
                            if (e.ID == 5) {
                                self.cars = e.ProductConfigOption;
                            }
                            if (e.ID == 7) {
                                self.teams = e.ProductConfigOption;
                            }
                            if (e.ID == 1) {
                                self.course = e.ProductConfigOption;
                            }
                        });
                        self.GetRegistration(id);
                    },
                };
                http.Post(op);
            },
            GetRegistration: function (id) {
                var self = this;
                var op = {
                    url: "/ShopModule/Product/GetRegistration",
                    data: {
                        id: id,
                    },
                    OnSuccess: function (data) {
                        data.data.forEach((e) => {
                            self.plainOptions.push({
                                value: e.RegistrationID,
                                label: e.Address,
                            });
                        });
                        self.GetCommissionMappingList(id);
                    },
                };
                http.Post(op);
            },
            GetCommissionMappingList: function (id) {
                var self = this;
                var op = {
                    url: "/ShopModule/Product/GetCommissionMappingList",
                    data: {},
                    OnSuccess: function (data) {
                        data.data.forEach((e) => {
                            self.Commisionoptions.push({
                                value: e.ID,
                                label: e.Name,
                                Remarks: e.Remarks,
                                ShowName: e.ShowName,
                            });
                        });
                        self.Remarks = data.data[0].Remarks;
                        self.ShowName = data.data[0].ShowName.split(",");
                        self.GetEditData(id);
                    },
                };
                http.Post(op);
            },

            GetEditData: function (id) {
                var self = this;
                var op = {
                    url: "/ShopModule/Product/GetEditData",
                    data: {
                        id: id,
                    },
                    OnSuccess: function (data2) {
                        if (id == 0) {
                            self.FormModel = data2.data;
                        } else {
                            var data3 = data2.data;
                            self.CommissionID = data3.CommissionID;
                            self.Inventory = data3.Inventory;
                            self.FormModel = data3;
                            self.Purchasershows = data3.Purchaser == 1 ? true : false;
                            self.IsSendTop = data3.IsSendTop == 1 ? true : false;
                            self.CommissionIsSendTop =
                                data3.CommissionIsSendTop == 1 ? true : false;
                            self.Commisionshows = data3.Commision == 1 ? true : false;
                            self.Advanceshows = data3.BuyMoneyType == 1 ? true : false;
                            self.FormModel.ProductExImage = imagedata.UploadDataList(
                                data3.ProductExImage,
                                { url: "ImagePath" }
                            );
                            self.setProductExParameter(data3);
                            self.setProductTrainPlace(data3);
                            self.setRemarks(data3);
                            self.Limitshows = data3.Limit == 1 ? true : false;
                            if (self.FormModel.SaleMoney > 0) {
                                self.Advancedisabled = false;
                            }
                            if (self.FormModel.M2ActivityID > 0) {
                                self.OriginalMoneyShow = true;
                                self.M2ActivityID = self.FormModel.M2ActivityID;
                            }
                        }
                        self.CommissionDetails =
                            data2.data.CommissionDetails.length > 0
                                ? data2.data.CommissionDetails
                                : [
                                    { Mode: 0, Proportion: 33.33, Orders: 1 },
                                    { Mode: 0, Proportion: 33.33, Orders: 2 },
                                    { Mode: 0, Proportion: 33.33, Orders: 3 },
                                ];
                        self.setCommission(data2.data.CommissionDetails);
                        self.Commisionoptions.forEach((j) => {
                            if (j.value == self.FormModel.MappingID) {
                                self.Remarks = j.Remarks;
                                self.ShowName = j.ShowName.split(",");
                            }
                        });

                        //加载商品规格信息
                        self.$route.query.M2ActivityProductID == 0 &&
                            self.GetSpectionNew(id);

                    },
                };
                http.Post(op);
            },
            //获取商品规格列表
            getProductSpecification(Pid) {
                var self = this;
                var op = {
                    url: "/ShopModule/Product/Ac_ProductSpecification",
                    data: { ProductID: Pid },
                    OnSuccess: function (res) {
                        console.log("规格ID：", res.data);
                        var obj = res.data;
                        if (res.data == null) return;
                        console.log("OBJJJJJJJJJJJ", obj);
                        for (var i = 0; i < obj.length; i++) {
                                var item = {
                                    defaultValue: self.SelectData[0].ID,
                                    SelectData: self.SelectData,
                                    SelectedList: [],
                                    PSFid: obj[i].ID,
                                };
                                self.GoodsSpecificationList.push(item);
                                self.getProductSpecificationDetail(
                                    obj[i].ID,
                                    obj[i].SFid,
                                );
                           
                        }
                    },
                };
                http.Post(op);
            },
            //获取商品规格明细列表
            getProductSpecificationDetail(PSFid, SFid) {
                console.log("获取详细信息");
                var self = this;
                (function (PSFid, SFid) {
                    var op = {
                        url: "/ShopModule/Product/Ac_ProductSpecificationDetail",
                        data: { PSFid: PSFid },
                        OnSuccess: function (res) {
                            console.log(res);
                            var obj = res.data;
                            for (var i = 0; i < obj.length; i++) {
                                var item = {
                                    ID: i,
                                    Name: obj[i].PSFName,
                                    ProductID: self.ProductID,
                                    GgID: SFid,
                                    PSFid: obj[i].PSFid,
                                    PSFDetailID: obj[i].ID,
                                };
                                for (var j = 0; j < self.GoodsSpecificationList.length; j++) {
                                    if (self.GoodsSpecificationList[j].PSFid == obj[i].PSFid) { 
                                        self.GoodsSpecificationList[j].SelectedList.push(item);
                                        self.GoodsSpecificationList[j].defaultValue = SFid;
                                    }
                                }
                            }
                            console.log("详细数据：", self.GoodsSpecificationList);
                        },
                    };
                    http.Post(op);
                })(PSFid, SFid)
            },
            setProductExParameter: function (data) {
                var self = this;
                data.ProductExParameter.forEach((e) => {
                    if (e.ConfigID == 5) {
                        self.getChecked(e.ConvigValue.split(","), self.cars);
                        self.carsdisabled = e.ConvigValue.split(",");
                    }
                    if (e.ConfigID == 6) {
                        self.Intended = e.ConvigValue;
                    }
                    if (e.ConfigID == 7) {
                        self.getChecked(e.ConvigValue.split(","), self.teams);
                        self.teamsdisabled = e.ConvigValue.split(",");
                    }
                    if (e.ConfigID == 1) {
                        self.getChecked(e.ConvigValue.split(","), self.course);
                        self.coursedisabled = e.ConvigValue.split(",");
                    }
                    if (e.ConfigID == 2) {
                        self.Intendedcourse = e.ConvigValue;
                    }
                });
            },
            getProductExParameter: function () {
                var self2 = this;
                var ProductExParameter = new Array();
                if (self2.forChecked(self2.cars)) {
                    ProductExParameter.push({
                        ConvigValue: self2.getConvigValue(self2.cars),
                        ConfigID: self2.cars[0].ID,
                    });
                }
                if (self2.Intended.length > 0) {
                    ProductExParameter.push({ ConvigValue: self2.Intended, ConfigID: 6 });
                }
                if (self2.forChecked(self2.teams)) {
                    ProductExParameter.push({
                        ConvigValue: self2.getConvigValue(self2.teams),
                        ConfigID: self2.teams[0].ID,
                    });
                }
                if (self2.forChecked(self2.course)) {
                    ProductExParameter.push({
                        ConvigValue: self2.getConvigValue(self2.course),
                        ConfigID: self2.course[0].ID,
                    });
                }
                if (self2.Intendedcourse.length > 0) {
                    ProductExParameter.push({
                        ConvigValue: self2.Intendedcourse,
                        ConfigID: 2,
                    });
                }
                if (ProductExParameter.length > 0) {
                    return ProductExParameter;
                }
            },
            imgChange: function (info) {
                var self = this;
                console.log("info:", info);
                if (info.file.status === "error") {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    } else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    info.fileList.pop();
                }
                self.FormModel.ProductExImage = info.fileList;
                console.log(info.fileList);
            },
            SaleMoneyChange: function (info) {
                var self = this;
                if (info > 0) {
                    self.Advancedisabled = false;
                } else {
                    self.Advancedisabled = true;
                    self.Advanceshows = false;
                }
            },
            removeImg: function (data) {
                var self = this;
                self.FormModel.ProductExImage = data.fileList;
            },
            AdvanceChange: function (e) {
                var self = this;
                self.Advanceshows = e;
            },
            CommisionChange: function (e) {
                var self = this;
                self.Commisionshows = e;
            },
            LimitChange: function (e) {
                var self = this;
                self.Limitshows = e;
            },
            Commisionoptionschange: function (e) {
                var self = this;
                self.Commisionoptions.forEach((j) => {
                    if (j.value == e.target.value) {
                        self.Remarks = j.Remarks;
                        self.ShowName = j.ShowName.split(",");
                    }
                });
            },
            setRemarks: function (data) {
                var self = this;
                self.Commisionoptions.forEach((j) => {
                    if (j.value == data.MappingID) {
                        self.Remarks = j.Remarks;
                    }
                });
            },
            setCommission: function (data) {
                var self = this;
                for (var i = 0; i < data.length; i++) {
                    self.CommissionDetails[i].MaxSwitch =
                        data[i].MaxSwitch == 1 ? true : false;
                    self.CommissionDetails[i].MinSwitch =
                        data[i].MinSwitch == 1 ? true : false;
                }
            },
            getCommission: function () {
                var self = this;
                for (var i = 0; i < self.CommissionDetails.length; i++) {
                    self.CommissionDetails[i].MaxSwitch =
                        self.CommissionDetails[i].MaxSwitch == true ? 1 : 0;
                    self.CommissionDetails[i].MinSwitch =
                        self.CommissionDetails[i].MinSwitch == true ? 1 : 0;
                }
                return self.CommissionDetails;
            },
            PurchaserChange: function (e) {
                var self = this;
                self.Purchasershows = e;
            },
            forChecked: function (data) {
                var forchecked = false;
                data.forEach((e) => {
                    if (e.Checked == true) {
                        forchecked = true;
                    }
                });
                return forchecked;
            },
            getConvigValue: function (data) {
                var ConvigValue = "";
                data.forEach((e) => {
                    if (e.Checked == true) {
                        ConvigValue = ConvigValue + "," + e.Value;
                    }
                });
                return ConvigValue.substring(1, ConvigValue.length);
            },
            getChecked: function (data, tag) {
                data.forEach((e) => {
                    tag.forEach((j) => {
                        if (e == j.Value) {
                            j.Checked = true;
                        }
                    });
                });
            },
            getProductExImage: function () {
                var self = this;
                var keyArray = new Array();
                if (self.FormModel.ProductExImage != null) {
                    self.FormModel.ProductExImage.forEach((e) => {
                        keyArray.push({ ImagePath: e.response });
                    });
                }
                return keyArray;
            },
            getProductTrainPlace: function () {
                var self = this;
                var keyArray = new Array();
                self.ProductTrainPlace.forEach((e) => {
                    keyArray.push({ RegistrationID: e });
                });
                return keyArray;
            },
            setProductTrainPlace: function (data) {
                var self = this;
                data.ProductTrainPlace.forEach((e) => {
                    self.ProductTrainPlace.push(e.RegistrationID);
                });
            },
            Tipsconfirm(e) {
                this.$info({
                    title: "提示",
                    content: "失败：" + e,
                    okText: "确定",
                });
            },
            checkMoneys: function () {
                var self = this;
                var err = "";
                var rt = true;
                self.CommissionDetails.forEach((e) => {
                    if (e.MaxMoneys < e.MinMoneys && e.MaxSwitch == 1 && e.MinSwitch == 1) {
                        err += self.ShowName[e.Orders - 1] + " 最大值小于最小值，";
                        rt = false;
                    }
                });
                if (err != "") {
                    self.Tipsconfirm(err);
                }
                return rt;
            },
            changeCommissionPlus: function (e) {
                var self = this;
                self.FormModel.CommissionPlus = e;
            },
            checkProductExImage: function () {
                var rt = true;
                var self = this;
                if (self.FormModel.ProductExImage != null) {
                    if (self.FormModel.ProductExImage.length == 0) {
                        rt = false;
                        self.$message.error("请上传图片！");
                    }
                } else {
                    rt = false;
                    self.$message.error("请上传图片！");
                }
                return rt;
            },
            AddRegistration() {
                var self = this;
                self.$router.push({ name: "ShopManage_SiteList" });
            },
        }, //方法
        computed: {}, //计算属性
        watch: {}, //侦听属性
        components: { Rich }, //组件
        beforeCreate: function () { }, //生命周期，创建前
        created: function () {
            this.ProductType = this.$route.query.ProductType;
            if (this.$route.query.ProductType == 2) {
                this.Place = "学校";
            }
            this.ProductID = this.$route.query.id;
            //this.show = this.ProductID!=0 && this.$route.query.disabled!=1?false:true;
            if (this.$route.query.disabled == 1) {
                this.disabled = true;
                this.Advancedisabled = true;
            }
        }, //生命周期，创建完成
        beforeMount: function () { }, //生命周期，组装前
        mounted: function () {
            if (this.$route.query.M2ActivityProductID == 0) {
                this.getTableData(this.ProductID);
                this.M2ActivityID = this.$route.query.M2ActivityID;
            } else {
                this.getTableData(this.$route.query.M2ActivityProductID);
                this.M2ActivityID = this.$route.query.M2ActivityID;
                this.OriginalMoneyShow = true;
            }
            if (this.$route.query.commission == 1) {
                this.baseinformation = false;
            }
            this.ReturnUrl = this.$route.query.ReturnUrl;
            this.GetSpecification();
            this.GetCustomSpecificationData();
        }, //生命周期，组装完成
        beforeUpdate: function () { }, //生命周期，data更新前
        updated: function () { }, //生命周期，data更新完成
        beforeDestroy: function () { }, //生命周期，销毁前
        destroyed: function () { }, //生命周期，销毁完成
    };
</script>