var UploadData = function (uid, name, url) {
    this.uid = typeof uid == 'string' ? uid : uid.toString();
    this.name = typeof name == 'string' ? name : name.toString();
    this.url = typeof url == 'string' ? url : url.toString();
    this.response = typeof url == 'string' ? url : url.toString();
    this.status = 'done';
}
var UploadDataList = function (array, op) {
    if (array.length <= 0)
        return [];
    else if (typeof array[0] == "string")
        return UploadDataListFromStringList(array);
    else if (typeof array[0] == "object")
        return UploadDataListfromObjList(array, op);
    else
        return [];
}
var UploadDataListfromObjList = function (array, op) {
    var namefield = "name";
    var urlfield = "url";
    var uidfield = "uid";
    if (op) {
        if (op.name)
            namefield = op.name
        if (op.url)
            urlfield = op.url
        if (op.uid)
            uidfield = op.uid
    }
    var result = [];
    for (var i = 0; i < array.length; i++) {
        var name = array[i][namefield] || i;
        var uid = array[i][uidfield] || i;
        var url = array[i][urlfield] || "";
        var obj = new UploadData(uid, name, url)
        result.push(obj);
    }
    return result;
}
var UploadDataListFromStringList = function (array) {
    var result = [];
    for (var i = 0; i < array.length; i++) {
        var name = '';
        var uid = i;
        var url = array[i]
        var obj = new UploadData(uid, name, url)
        result.push(obj);
    }
    return result;
}
export default {
    UploadData,
    UploadDataList
}